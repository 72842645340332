import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';
import {DataService} from "../../../shared/services/data.service";
import {AppDataService} from "../../../shared/services/app-data.service";
import {EtisalatUserService} from "../etisalat-user.service";
import {ApiRequestService} from "../../../shared/services/api-request.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {CommonService} from "../../../shared/services/common.service";

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {

  isSaveButtonClicked = false;
  isNotificationEditMode = true;
  notificationData = {
    sms_phone_number: '',
    sms_enabled: false,
    whatsapp_phone_number: '',
    whatsapp_enabled: false
  };
  countriesData = [];
  countryCodeOrName = '';
  showCountriesList = { whatsapp: false, sms: false };
  @ViewChild('searchNameOrCode') searchNameOrCode: ElementRef;
  @ViewChild('contactWhatsapp') public contactWhatsapp;
  @ViewChild('contactSMS') public contactSMS;
  @ViewChild('email') public email;
  @ViewChild('name') public name;
  @ViewChild('password') public password;
  countryISOCode = { whatsapp: null, sms: null };
  contactNumber: string;
  countryNameCode = { whatsapp: '', sms: '' };
  termsConditionInvalid = false;
  ErrorMsg = '';
  invalidEmail = false;
  emailExist = false;
  invalidPassword = false;
  invalidName = false;
  invalidPhone = false;
  isRegisterClicked = false;
  isSignUpClicked = false;
  screenTobeAppear = 'signup';
  termsAndConditionsChecked = false;
  isShowPassword = false;
  signup_data: any = {
    first_name: null,
    last_name: '',
    email: null,
    password: null,
    phone_number: '',
    invite_token: null,
    user_type: null,
    organisation: null,
    name: ''
  };

  constructor(public dataService: DataService, private apiRequest: ApiRequestService,
              private appDataService: AppDataService, private etisalatUserService: EtisalatUserService,
              private router: Router, public commonService: CommonService) { }

  ngOnInit(): void {
    if (this.commonService.isPlatformBrowser) {
      this.apiRequest.getUserData();
      this.getDetailsFromToken();
      this.fetchHomePageData();
    }
  }

  fetchHomePageData() {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.dataService.countryNameCode = data.country_code ? data.country_code.toLowerCase() : '';
      this.countryNameCode = { whatsapp: this.dataService.countryNameCode, sms: this.dataService.countryNameCode };
      this.getCountriesData();
    });
  }

  getDetailsFromToken() {
    this.dataService.showHideLoader(true);
    const token = window.location.href.split('?')[1].split('=')[1];
    this.etisalatUserService.isInvalidAccess = false;
    this.etisalatUserService.isEtisalatUserFetched = false;
    this.etisalatUserService.token = token;
    this.apiRequest.getEtisalatUserData(token).subscribe(resp => {
      this.etisalatUserService.userAndKickOffDetails = resp;
      const user = this.etisalatUserService.userAndKickOffDetails.user;
      this.dataService.etisalatUserData = user;
      this.dataService.specingId = this.etisalatUserService.userAndKickOffDetails['build_card']['id'];
      if (this.dataService.user && (this.dataService.user.email === user.email)) {
        this.etisalatUserService.isEtisalatUserFetched = true;
      } else if (this.dataService.user && (this.dataService.user.email !== user.email)) {
        this.etisalatUserService.isInvalidAccess = true;
      } else {
        this.signup_data.email = user.email;
        if (this.etisalatUserService.userAndKickOffDetails.signup_page) {
          this.screenTobeAppear = 'signup';
          this.signup_data.name = (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '');
          if (user.phone_number) {
            this.signup_data.phone_number = user.phone_number.replace('-', '');
            this.notificationData.sms_phone_number = this.signup_data.phone_number;
            this.notificationData.sms_enabled = true;
          }
        } else {
          this.screenTobeAppear = 'login';
        }
      }
      const refresh = window.sessionStorage.getItem('refresh');
      if (refresh === null){
        window.location.reload();
        window.sessionStorage.setItem('refresh', "1");
      }
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  checkIfEmailValid(): boolean {
    return !this.dataService.validateEmail(this.email.value);
  }

  validateEmail() {
    this.ErrorMsg = '';
    if (this.checkIfEmailValid()) {
      this.invalidEmail = true;
      return;
    } else {
      this.invalidEmail = false;
    }
  }

  validateName() {
    if (this.checkIfNameValid()) {
      this.invalidName = true;
      return;
    } else {
      this.invalidName = false;
    }
  }

  checkIfNameValid(): boolean {
    if (this.isRegisterClicked) {
      return this.name && this.name.errors && (this.name.invalid || this.name.pristine);
    } else {
      return this.name && this.name.errors && (this.name.dirty || this.name.touched);
    }
  }

  validatePhoneNumber() {
    if (this.checkIfPhoneInValid(this.signup_data.phone_number)) {
      this.invalidPhone = true;
      return;
    } else {
      this.invalidPhone = false;
    }
  }

  setUserContact(event) {
    this.signup_data.phone_number = event;
  }

  validatePassword() {
    if (this.checkIfPasswordValid(false)) {
      this.invalidPassword = true;
      return;
    } else {
      this.invalidPassword = false;
    }
  }

  checkNotificationData() {
    const data = this.notificationData;
    if (data.whatsapp_enabled && this.checkIfPhoneInValid(data.whatsapp_phone_number)) {
      return true;
    } else if (data.sms_enabled && this.checkIfPhoneInValid(data.sms_phone_number)) {
      return true;
    }
    return false;
  }

  checkIfPasswordValid(isSignIn?: boolean): boolean {
    if (isSignIn) {
      return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
    } else if (!isSignIn) {
      if (this.isRegisterClicked) {
        return this.password && !!this.password.errors && (this.password.pristine || this.password.invalid);
      } else {
        return this.password && this.password.errors && (this.password.dirty || this.password.touched);
      }
    }
  }

  setNotificationData() {
    const preferences = this.dataService.user.message_preferences;
    if (preferences && preferences.whatsapp_phone_number) {
      this.notificationData.whatsapp_phone_number = preferences.whatsapp_phone_number.replace('-', '').trim();
      this.notificationData.whatsapp_enabled = preferences.whatsapp_enabled;
    } else {
      this.notificationData.whatsapp_phone_number = this.dataService.user.phone_number.replace('-', '').trim();
    }
    if (preferences && preferences.sms_phone_number) {
      this.notificationData.sms_phone_number = preferences.sms_phone_number.replace('-', '').trim();
      this.notificationData.sms_enabled = preferences.sms_enabled;
    } else {
      this.notificationData.sms_phone_number = this.dataService.user.phone_number.replace('-', '').trim();
    }
    this.setISOCode('whatsapp', this.notificationData.whatsapp_phone_number);
    this.setISOCode('sms', this.notificationData.sms_phone_number);
  }

  checkIfPhoneInValid(contactNumber) {
    const phoneNumber = parsePhoneNumber(contactNumber.toString(), this.countryISOCode.whatsapp);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && contactNumber.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  toggleEditPreferences() {
    this.isNotificationEditMode = !this.isNotificationEditMode;
    this.notificationData.whatsapp_enabled = false;
    this.notificationData.sms_enabled = false;
    if (!this.isNotificationEditMode) {
      this.isSaveButtonClicked = false;
      this.showCountriesList.whatsapp = false;
      this.showCountriesList.sms = false;
      this.setNotificationData();
    }
  }

  toggleRadioButton(contactType) {
    if (contactType === 'whatsapp') {
      this.notificationData.whatsapp_enabled = !this.notificationData.whatsapp_enabled;
    } else if (contactType === 'SMS') {
      this.notificationData.sms_enabled = !this.notificationData.sms_enabled;
    }
    if (!this.notificationData.sms_enabled) {
      const defaultCountry2 = this.countriesData.find(country => country.dial_code.substr(1) === this.countryNameCode.sms);
      this.setPhoneNumber(defaultCountry2, '', 'SMS');
    } else if (!this.notificationData.whatsapp_enabled) {
      const defaultCountry1 = this.countriesData.find(country => country.dial_code.substr(1) === this.countryNameCode.whatsapp);
      this.setPhoneNumber(defaultCountry1, '', 'whatsapp');
    }
  }

  getCountriesData() {
    this.apiRequest.getCountriesList('../../../../assets/countries.json').subscribe(resp => {
      this.countriesData = resp;
      let countryNameCode;
      countryNameCode = this.countriesData.find(country => (country.code.toLowerCase() === this.dataService.countryNameCode)).dial_code
      countryNameCode = countryNameCode.replace('+', '');
      countryNameCode = countryNameCode.replace('+', '').replace(/\s/g, '');
      this.dataService.countryNameCode = countryNameCode;
      this.countryNameCode = { whatsapp: countryNameCode, sms: countryNameCode };
      const defaultCountry1 = this.countriesData.find(country => country.dial_code.substr(1) === this.countryNameCode.whatsapp);
      const defaultCountry2 = this.countriesData.find(country => country.dial_code.substr(1) === this.countryNameCode.sms);
      this.setPhoneNumber(defaultCountry1, '', 'whatsapp');
      this.setPhoneNumber(defaultCountry2, '', 'SMS');
    });
  }

  setPhoneNumber(country, event, contactType?) {
    if (event) {
      event.stopPropagation();
    }
    const user = this.dataService.user;
    if (event || (!user && !this.appDataService.urlParameters.phone_number) || (user && !user.phone_number)) {
      if (contactType === 'whatsapp') {
        this.notificationData.whatsapp_phone_number = country.dial_code;
      } else if (contactType === 'SMS') {
        if (!this.notificationData.sms_phone_number) {
          this.notificationData.sms_phone_number = country.dial_code;
        }
      }
    }
    this.showCountriesList.whatsapp = false;
    this.showCountriesList.sms = false;
    this.countryCodeOrName = '';
    if (contactType === 'whatsapp') {
      this.countryISOCode.whatsapp = country.code;
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(/\s/g, '');
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(this.countryNameCode.whatsapp, (this.countryNameCode.whatsapp + ' '));
    } else if (contactType === 'SMS') {
      this.countryISOCode.sms = country.code;
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(/\s/g, '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(this.countryNameCode.sms, (this.countryNameCode.sms + ' '));
    }
  }

  toggleCountriesList(event, listType) {
    event.stopPropagation();
    if (listType === 'whatsapp') {
      this.showCountriesList.whatsapp = !this.showCountriesList.whatsapp;
    } else if (listType === 'SMS') {
      this.showCountriesList.sms = !this.showCountriesList.sms;
    }
    this.countryCodeOrName = '';
    if (this.showCountriesList.whatsapp || this.showCountriesList.sms) {
      this.searchNameOrCode.nativeElement.focus();
    }
  }

  acceptNumberOnly(event, contactType): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode < 48 && charCode !== 43) || charCode > 57)) {
      return false;
    }
    let phoneNumber;
    if (contactType === 'whatsapp') {
      phoneNumber = parsePhoneNumber(this.notificationData.whatsapp_phone_number.toString(), this.dataService.countryISOCode);
    } else if (contactType === 'SMS') {
      phoneNumber = parsePhoneNumber(this.notificationData.sms_phone_number.toString(), this.dataService.countryISOCode);
    }
    if (phoneNumber) {
      const dialCode = '+' + phoneNumber.countryCallingCode;
      if (contactType === 'whatsapp') {
        this.countryISOCode.whatsapp = this.countriesData.find(country => (country.dial_code === dialCode)).code;
        this.countryNameCode.whatsapp = phoneNumber.countryCallingCode.toString();
      } else if (contactType === 'SMS') {
        this.countryISOCode.sms = this.countriesData.find(country => (country.dial_code === dialCode)).code;
        this.countryNameCode.sms = phoneNumber.countryCallingCode.toString();
      }
    }
    return true;
  }

  setFlagForDialCode(contactType) {
    let phoneNumber;
    if (contactType === 'whatsapp') {
      phoneNumber = parsePhoneNumber(this.notificationData.whatsapp_phone_number.toString(), this.countryISOCode.whatsapp);
    } else if (contactType === 'SMS') {
      phoneNumber = parsePhoneNumber(this.notificationData.sms_phone_number.toString(), this.countryISOCode.sms);
    }
    if (!phoneNumber && this.contactNumber) {
      if (contactType === 'whatsapp') {
        this.setISOCode('whatsapp', this.notificationData.whatsapp_phone_number);
      } else if (contactType === 'SMS') {
        this.setISOCode('sms', this.notificationData.sms_phone_number);
      }
    }
  }

  setISOCode(contactType, contactNumber) {
    const iSOCode = this.countriesData.find(country => (contactNumber.toString().includes(country.dial_code)));
    if (iSOCode) {
      this.countryISOCode[contactType] = iSOCode.code;
    } else {
      this.countryISOCode[contactType] = '';
    }
    if (contactType === 'whatsapp') {
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(/\s/g, '');
      this.notificationData.whatsapp_phone_number = this.notificationData.whatsapp_phone_number.replace(this.countryNameCode.whatsapp, (this.countryNameCode.whatsapp + ' '));
    } else if (contactType === 'SMS') {
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(/\s/g, '');
      this.notificationData.sms_phone_number = this.notificationData.sms_phone_number.replace(this.countryNameCode.sms, (this.countryNameCode.sms + ' '));
    }
  }

  acceptAlphaNumeric(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode === 32) || (charCode === 43) || (charCode > 47 || charCode < 58) && ((charCode > 64 || charCode < 91)) && ((charCode > 96 || charCode < 123))) {
      return true;
    } else {
      return false;
    }
  }

  validateTandCOnCheckboxChange() {
    this.termsAndConditionsChecked = !this.termsAndConditionsChecked;
  }

  loginSignup() {
    if (this.isSignUpClicked) { return false; }
    this.validateEmail();
    this.isSaveButtonClicked = true;
    if (this.screenTobeAppear === 'login') {
      if (this.checkIfPasswordValid(true)) {
        this.invalidPassword = true;
        return;
      } else {
        this.invalidPassword = false;
      }
    } else {
      this.isRegisterClicked = true;
      this.validateName();
      this.validatePhoneNumber();
      if (this.isNotificationEditMode && this.checkNotificationData()) {
        return false;
      }
      if (this.checkIfPasswordValid(false)) {
        this.invalidPassword = true;
        return;
      } else {
        this.invalidPassword = false;
      }
      if (this.name.value.includes(' ')) {
        this.signup_data.first_name = this.name.value.split(' ').slice(0, -1).join(' ');
        this.signup_data.last_name = this.name.value.split(' ').slice(-1).join(' ');
      }
    }
    this.signup_data.email = this.signup_data.email.toLowerCase();
    if ((this.screenTobeAppear === 'signup') ? (this.invalidName || this.invalidEmail || this.invalidPhone || this.invalidPassword || !this.termsAndConditionsChecked) : (this.invalidEmail || this.invalidPassword)) {
      return false;
    }
    if ((this.screenTobeAppear === 'signup') && this.isNotificationEditMode) {
      this.signup_data['preferences'] = this.notificationData;
      if (this.checkIfPhoneInValid(this.notificationData.sms_phone_number)) {
        this.signup_data.preferences.sms_phone_number = '';
      }
      if (this.checkIfPhoneInValid(this.notificationData.whatsapp_phone_number)) {
        this.signup_data.preferences.whatsapp_phone_number = '';
      }
    }
    if (this.screenTobeAppear === 'login') {
      Object.keys(this.signup_data).forEach(key => {
        if ((key !== 'password') && (key !== 'email')) {
          delete this.signup_data[key];
        }
      });
    }
    this.isSignUpClicked = true;
    this.dataService.showHideLoader(true);
    if (this.screenTobeAppear === 'signup') {
      this.apiRequest.updateEtisalatuser(this.signup_data, this.screenTobeAppear).subscribe(resp => {
        this.dataService.user = resp.user;
        this.dataService.setCookie();
        this.etisalatUserService.isEtisalatUserFetched = true;
        this.dataService.showHideLoader(false);
      }, error => {
        this.handleErrors(error);
      });
    } else {
      this.apiRequest.login_signup(this.signup_data, this.screenTobeAppear).subscribe(resp => {
        this.dataService.user = resp.user;
        this.dataService.setCookie();
        this.etisalatUserService.isEtisalatUserFetched = true;
        this.dataService.showHideLoader(false);
      }, error => {
        this.handleErrors(error);
      });
    }
  }

  handleErrors(error) {
    if (Object.keys(error.error.errors).includes('email')) {
      if (error.error.errors.email && error.error.errors.email[0] === 'is already taken') {
        this.emailExist = true;
        this.invalidPassword = false;
      } else {
        this.invalidEmail = true;
        this.invalidPassword = false;
      }
    }
    if (Object.keys(error.error.errors).includes('password')) {
      this.invalidEmail = false;
      this.invalidPassword = true;
    }
    this.isSignUpClicked = false;
    this.isSaveButtonClicked = false;
    this.dataService.showHideLoader(false);
  }

  removeErrors() {
    this.invalidEmail = false;
    this.emailExist = false;
    this.invalidPassword = false;
    this.invalidName = false;
    this.invalidPhone = false;
  }

  toggleLoginSignup(screen) {
    this.screenTobeAppear = screen;
  }

  forgotPassword() {
    this.ErrorMsg = '';
    Object.keys(this.signup_data).forEach(key => {
      if (key !== 'email') {
        delete this.signup_data[key];
      }
    });
    this.apiRequest.login_signup(this.signup_data, 'forgot').subscribe((data: any) => {
      this.screenTobeAppear = 'resetSuccess';
      this.ErrorMsg = '';
    }, error => {
      if (error.error.errors.email !== null) {
        this.ErrorMsg = 'Email ' + error.error.errors.email;
      } else {
        this.ErrorMsg = error.message;
      }
    });
  }

  openUrls(url) {
    window.open(url);
  }

  checkIfToDisableButton() {
    const data = this.signup_data;
    if (!data.email || !data.name || !data.password || !data.phone_number ||
      this.checkNotificationData() || this.checkIfPhoneInValid(this.signup_data.phone_number) ||
      !this.termsAndConditionsChecked) {
      return true;
    } else {
      return false;
    }
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  redirectToMSA() {
    const URL = environment.ETISALAT_FE_URL + 'msa';
    window.open(URL, '_blank');
  }

}
