import { Component, OnInit } from '@angular/core';
import {EtisalatUserService} from "./etisalat-user.service";
import {DataService} from "../../shared/services/data.service";

@Component({
  selector: 'app-etisalat-flow',
  templateUrl: './etisalat-flow.component.html',
  styleUrls: ['./etisalat-flow.component.scss']
})
export class EtisalatFlowComponent implements OnInit {

  constructor(public etisalatUserService: EtisalatUserService, public dataService: DataService) { }

  ngOnInit(): void {}

}
